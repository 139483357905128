const TogglePayment = {
  init() {
    this.bindEvents();
  },

  bindEvents() {
    const toggleButtons = document.querySelectorAll('.toggle-payment-info .toggle-button');    
    toggleButtons.forEach(button => {
      button.addEventListener('click', (e) => {
        this.handleToggle(e);
      });
    });
  },

  handleToggle(event) {
    const paymentDetails = event.currentTarget.nextElementSibling;
    const icon = event.currentTarget.querySelector('.toggle-icon');
    
    paymentDetails.classList.toggle('hidden');
    icon.classList.toggle('rotated');
  }
};

export default TogglePayment; 